export const initializeGtag = () => {
  window.dataLayer = window.dataLayer || [];
  window.gtag = (...args: any[]) => {
    window.dataLayer.push(args);
  };
  window.gtag('consent', 'default', {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    personalization_storage: 'denied',
    functionality_storage: 'granted',
    security_storage: 'granted',
    wait_for_update: 500,
  });
  window.gtag('set', 'ads_data_redaction', true);
};

export const loadScript = (id: string, src: string) => {
  const script = document.createElement('script');
  script.id = id;
  script.src = src;

  document.head.appendChild(script);
};
