import { compile, Key, pathToRegexp } from 'path-to-regexp';

import markets from '../config/markets.json';
import urlPartLocalizations from '../config/url-part-localizations.json';
import { removeProtocolAndDomainFromUrl } from './urlHelpers';

export type SemboMarket = {
  host: string;
  lang: string;
  countryISO: string;
  hostAliases?: string[];
};

export const publishedMarkets: SemboMarket[] = markets.publishedMarkets;
export const supportedMarkets: SemboMarket[] = [
  ...publishedMarkets,
  ...markets.otherMarkets,
];

export const supportedHosts = Array.from(
  new Set(supportedMarkets.map((market) => market.host))
);

export const supportedLanguages = Array.from(
  new Set(supportedMarkets.map((market) => market.lang))
);

export const supportedCountries = Array.from(
  new Set(supportedMarkets.map((market) => market.countryISO))
);

export const getFallbackMarket = (): SemboMarket => {
  const res = supportedMarkets.find(
    (market) => market.host === markets.fallbackMarketDomain
  );

  if (!res) {
    throw new Error('could not find fallback market');
  }

  return res;
};

export const tryExtractMarketFromUrl = (urlPath: string): string | null =>
  /\/(.+?)(\/|$)/.exec(urlPath)?.[1] ?? null;

export type LocaleString = `${Lowercase<string>}-${Uppercase<string>}`;

export const getLocaleByMarket = (market: SemboMarket): LocaleString =>
  `${market.lang.toLowerCase() as Lowercase<string>}-${market.countryISO.toUpperCase() as Uppercase<string>}`;

export const getUnlocalizedUrl = (
  market: SemboMarket,
  path: string
): {
  url: string;
  path: string;
  params: Record<string, string>;
} | null => {
  path = removeProtocolAndDomainFromUrl(path);

  const domainRoutes = (urlPartLocalizations as any)[market.host];

  if (!domainRoutes) {
    return null;
  }

  const pathWithoutMarketPrefix = getPathnameWithoutMarketPrefix(
    path,
    market,
    true
  );

  for (const [unlocalizedPath, localizedPath] of Object.entries(domainRoutes)) {
    if (typeof localizedPath !== 'string') {
      continue;
    }

    const keys: Key[] = [];
    const regexp = pathToRegexp(localizedPath, keys);
    const result = regexp.exec(pathWithoutMarketPrefix);

    if (result) {
      // Extract parameters from the matched path
      const params = keys.reduce<Record<string, string>>((acc, key, index) => {
        const value = result[index + 1];
        if (value) {
          acc[key.name] = value;
        }
        return acc;
      }, {});

      const toPath = compile(unlocalizedPath);
      const compiledUrl = toPath(params);

      if (compiledUrl === unlocalizedPath) {
        return null;
      }

      return { url: compiledUrl, path: unlocalizedPath, params };
    }
  }

  return null;
};

export const getLocalizedUrl = (
  market: SemboMarket,
  path: string
): string | null => {
  path = removeProtocolAndDomainFromUrl(path);

  const domainRoutes = (urlPartLocalizations as any)[market.host];

  if (!domainRoutes) {
    return null;
  }

  const pathWithoutMarketPrefix = getPathnameWithoutMarketPrefix(
    path,
    market,
    true
  );

  for (const pathToPage in domainRoutes) {
    const keys: Key[] = [];
    const regexp = pathToRegexp(pathToPage, keys);
    const result = regexp.exec(pathWithoutMarketPrefix);

    if (result) {
      // Extract parameters from the matched path
      const params = keys.reduce<Record<string, string>>((acc, key, index) => {
        const value = result[index + 1];
        if (value) {
          acc[key.name] = value;
        }
        return acc;
      }, {});

      const toPath = compile(domainRoutes[pathToPage]);
      const localizedUrl = toPath(params);

      if (localizedUrl === pathToPage) {
        return null;
      }

      return localizedUrl;
    }
  }

  return null;
};

export const getPathnameWithoutMarketPrefix = (
  pathname: string,
  market: SemboMarket,
  includeAliases?: boolean
) =>
  [market.host, ...(includeAliases ? (market.hostAliases ?? []) : [])].reduce(
    (res, host) => res.replace(host + '/', ''),
    pathname
  );

export const getLocalizedAbsoluteUrl = (
  market: SemboMarket,
  path: string
): string => {
  const localizedUrl = getLocalizedUrl(market, path);

  if ((localizedUrl ?? path).startsWith('http')) {
    return localizedUrl ?? path;
  }

  return `https://${market.host}${localizedUrl ?? path}`;
};

export const getPublishedMarketPaths = () =>
  publishedMarkets.map((m) => {
    return {
      params: {
        market: m.host,
      },
    };
  });

export const getMarketPaths = () =>
  supportedMarkets.map((m) => {
    return {
      params: {
        market: m.host,
      },
    };
  });

export const isPublishedMarket = (marketOrHost: string | SemboMarket) =>
  publishedMarkets.some(
    (publishedMarket) =>
      publishedMarket.host ===
      (typeof marketOrHost === 'string'
        ? getMarket(marketOrHost)?.host
        : marketOrHost.host)
  );

export const ensureStartsWithWww = (url: string) =>
  url.replace(/^(http[s]?:\/\/|)(www\.|)/, 'www.');

export const isDomainForSpecificMarket = (host: string) => !!getMarket(host);

const findMarketByGivenMarkets = (
  host: string,
  markets: SemboMarket[],
  allowAliases: boolean
): SemboMarket | undefined =>
  markets.find(
    (markets) =>
      markets.host === host ||
      markets.host === ensureStartsWithWww(host) ||
      (allowAliases && markets.hostAliases?.includes(host)) ||
      markets.hostAliases?.includes(ensureStartsWithWww(host))
  );

export const getMarket = (
  host: string,
  allowAliases = true
): SemboMarket | undefined =>
  findMarketByGivenMarkets(host, supportedMarkets, allowAliases);

export const guessMarketByUrl = (href: string): SemboMarket | undefined => {
  const url = new URL(href);

  const market = getMarket(url.host);
  if (market) {
    return market;
  }

  const [, marketPath] = url.pathname.split('/');
  if (marketPath) {
    return getMarket(marketPath);
  }

  return undefined;
};

export const getMarketByCountryISO = (
  countryISO: string
): SemboMarket | undefined =>
  supportedMarkets.find(
    (supportedMarket) =>
      supportedMarket.countryISO.toLowerCase() === countryISO.toLowerCase()
  );

export const getMarketsByLanguage = (language: string): SemboMarket[] =>
  supportedMarkets.filter(
    (supportedMarket) =>
      supportedMarket.lang.toLowerCase() === language.toLowerCase()
  );

export const guessMarketByLanguage = (lang: string): SemboMarket | undefined =>
  supportedMarkets.find(
    (markets) => markets.lang.toLowerCase() === lang.toLowerCase()
  );

export const getPublishedMarket = (
  host: string,
  allowAliases = true
): SemboMarket | undefined =>
  findMarketByGivenMarkets(host, publishedMarkets, allowAliases);

export const addMarketPrefixIfDomainMatchesMarket = (
  market: SemboMarket,
  url: string
): string => {
  if (!url.startsWith(`/${market.host}`)) {
    return `/${market.host}${url}`;
  }

  return url;
};
