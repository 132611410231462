import './consent-style-overrides.css';

import { OSANO_SCRIPT_URL } from './constants';
import { initializeGtag, loadScript } from './utils';

const main = () => {
  initializeGtag();
  loadScript('osano', OSANO_SCRIPT_URL);
};

main();
